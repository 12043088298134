<script setup lang="ts">
import type { AssetFragment } from '#graphql-operations'
import { useCarouselScroll } from '~/composables/useCarouselScroll'
import { isVideo } from '~/utils/assets'

const props = defineProps<{
  assets: (AssetFragment & { alt: string })[]
  initialIndex: number
}>()

const carouselRef = ref<HTMLElement | null>(null)
const imageWrapperRef = ref<HTMLElement[]>([])
const itemWidth = ref(0)
const zoomedIndex = ref<number | null>(null)

provide('zoomedIndex', zoomedIndex)

const initialScrollPosition = computed(() => {
  if (!carouselRef.value)
    return 0
  const itemWidth = carouselRef.value.offsetWidth
  return props.initialIndex * itemWidth
})

const { x } = useScroll(carouselRef, { behavior: 'smooth' })

const { width: carouselWidth } = useElementSize(carouselRef)

useCarouselScroll(carouselRef as Ref<HTMLElement>)

useResizeObserver(carouselRef, (entries) => {
  const [entry] = entries

  itemWidth.value = entry?.target?.firstElementChild?.clientWidth || 0
})

const currentPage = computed(() => {
  if (!itemWidth.value) {
    return 0
  }

  return Math.round(x.value / itemWidth.value) + 1
})

const pages = computed(() => {
  if (!itemWidth.value) {
    return 0
  }

  return props.assets.length - Math.round(carouselWidth.value / itemWidth.value) + 1
})

const isFirst = computed(() => currentPage.value <= 1)
const isLast = computed(() => currentPage.value === pages.value)

function onClickNext() {
  x.value += itemWidth.value
}

function onClickPrev() {
  x.value -= itemWidth.value
}

function onClick(page: number) {
  x.value = (page - 1) * itemWidth.value
}

onMounted(() => {
  if (carouselRef.value) {
    carouselRef.value.scrollLeft = initialScrollPosition.value
  }

  window.addEventListener('keydown', handleKeyDown)
})

onUnmounted(() => {
  // Remove event listener when component is unmounted
  window.removeEventListener('keydown', handleKeyDown)
})

function handleKeyDown(event: KeyboardEvent) {
  if (event.key === 'ArrowLeft') {
    onClickPrev()
  }
  else if (event.key === 'ArrowRight') {
    onClickNext()
  }
}

function onZoom(index: number, value: boolean) {
  zoomedIndex.value = value ? index : null
}
</script>

<template>
  <div data-testid="ProductImageDetails" class="hfull flex of-hidden bg-background">
    <section data-testid="horizontalSlider" class="hfull wfull flex flex-row-reverse">
      <div data-testid="sliderContainer" class="relative hfull wfull">
        <div
          ref="carouselRef"
          data-testid="sliderWrapper"
          class="hfull w-[calc(100%+0px)] flex flex-nowrap touch-manipulation snap-x snap-mandatory of-x-auto of-y-hidden will-change-scroll no-scrollbar [&>*]:mr1.25 [&>*]:w0 [&>*]:shrink-0 [&>*]:basis-[calc(100%-5px)] [&>*]:snap-start"
        >
          <ProductImageDetail
            v-for="(asset, index) in assets" :key="index"
            :asset="asset"
            :index="index"
            :current-page="currentPage"
            @zoom="onZoom(index, $event)"
          />
        </div>

        <div v-if="isFirst" disabled class="pointer-events-none absolute bottom-0 left-0 top-0 flex cursor-default items-center justify-center pb0 md:w-10 xl:w-15" />
        <div v-else class="absolute bottom-0 left-0 top-0 w4 flex cursor-pointer items-center justify-center pb0 md:w-10 xl:w-15">
          <button
            v-wave="{ color: 'hsl(var(--background)' }"
            data-testid="arrowLeft"
            class="group relative hfull wfull flex cursor-pointer items-center justify-center of-hidden border-0 rd-br-0.5 rd-tr-0.5 bg-transparent outline-none transition-all duration-300 ease-in-out will-change-transform,opacity"
            @click="onClickPrev"
          >
            <span
              v-wave="{ color: 'hsl(var(--background)' }"
              class="relative top-0 h9 w9 flex shrink-0 grow-0 basis-9 cursor-pointer items-center justify-center of-hidden border-0 rd-1/2 p0 outline-none will-change-transform,opacity group-hover:bg-primary/15" style="transition: background 300ms ease-in-out, transform 75ms ease-in-out"
            >
              <Icon name="ph:caret-left-bold" class="block h6 w6 shrink-0 grow-0 basis-6 drop-shadow" />
            </span>
          </button>
        </div>

        <div v-if="isLast" disabled class="pointer-events-none absolute bottom-0 left-auto right-0 top-0 w4 flex cursor-default items-center justify-center pb0 md:w-10 xl:w-15" />
        <div v-else class="absolute bottom-0 left-auto right-0 top-0 w4 flex cursor-pointer items-center justify-center pb0 md:w-10 xl:w-15">
          <button
            v-wave="{ color: 'hsl(var(--background)' }"
            data-testid="arrowRight"
            class="group relative hfull wfull flex cursor-pointer items-center justify-center of-hidden border-0 rd-br-0.5 rd-tr-0.5 bg-transparent outline-none transition-all duration-300 ease-in-out will-change-transform,opacity"
            @click="onClickNext"
          >
            <span
              v-wave="{ color: 'hsl(var(--background)' }"
              class="relative top-0 h9 w9 flex shrink-0 grow-0 basis-9 cursor-pointer items-center justify-center of-hidden border-0 rd-1/2 p0 outline-none will-change-transform,opacity group-hover:bg-primary/15" style="transition: background 300ms ease-in-out, transform 75ms ease-in-out"
            >
              <Icon name="ph:caret-right-bold" class="block h6 w6 shrink-0 grow-0 basis-6 drop-shadow" />
            </span>
          </button>
        </div>
      </div>

      <ul class="m0 w21 flex shrink-0 flex-col items-center overflow-y-auto p3 no-scrollbar" data-testid="ProductImageDetailsThumbnailNav">
        <li
          v-for="(asset, index) in assets" :key="index"
          data-testid="ProductImageDetailsThumbnailNavItem"
          class="mb2.25 wfull list-none last:mb0"
        >
          <button
            data-testid="ProductImageDetailsThumbnailNavItemButton"
            class="relative wfull cursor-pointer border rd-1 bg-slate-100 p0"
            :class="currentPage === (index + 1) ? 'border-primary' : 'border-transparent'"
            @click="onClick(index + 1)"
          >
            <div data-testid="productImage" class="relative h0 wfull of-hidden rd-1 bg-slate-100 pt-[calc(133.333%)] [&>img]:absolute [&>img]:inset-0 [&>img]:m-auto [&>img]:hfull [&>img]:max-h-full [&>img]:max-w-full [&>img]:wfull [&>img]:object-cover [&>img]:object-center-center">
              <div v-if="isVideo(asset)" class="absolute inset-0 z1 flex items-center justify-center">
                <div class="h8 w8 flex items-center justify-center border border-white/50 rd-full bg-white/40 shadow-xl backdrop-blur transition-all duration-150 hover:bg-white/50">
                  <Icon name="ph:play-fill" class="text-white drop-shadow-md" />
                </div>
              </div>
              <NuxtImg
                height="100%"
                width="100%"
                decoding="async"
                loading="lazy"
                sizes="8.333vw"
                :src="isVideo(asset) ? asset.customFields?.videoPreview?.preview ?? asset.preview : asset.preview"
                :alt="asset.alt"
                data-testid="productImageView"
                class="aspect-3/4 rd-0.5 align-bottom"
              />
            </div>
          </button>
        </li>
      </ul>
    </section>
  </div>
</template>
