import type { ProductOptionFragment, ProductOptionGroupFragment, ProductVariantFragment } from '#graphql-operations'
import { IMAGE_OPTION_GROUP_CODES } from '~/constants'

export function useProductOptions(variants: ProductVariantFragment[]) {
  const route = useRoute()

  const selectedOptions = useState<Record<string, string | string[]>>(() => ({}))

  const selectedVariant = computed<ProductVariantFragment | undefined>(() => {
    return variants.find((variant) => {
      return variant.options.every((option) => {
        // Ensure every option in the variant matches the selected option and is in stock
        if (Array.isArray(selectedOptions.value[option.group.code])) {
          return null
        }
        return selectedOptions.value[option.group.code] === option.code
      })
    })
  })

  const selectedVariants = computed(() => {
    return variants.filter((variant) => {
      return variant.options.every((option) => {
        if (Array.isArray(selectedOptions.value[option.group.code])) {
          return selectedOptions.value[option.group.code].includes(option.code)
        }
        else {
          return selectedOptions.value[option.group.code] === option.code
        }
      })
    })
  })

  const isOutOfStock = computed(() => variants.every(variant => variant.stockLevel !== 'IN_STOCK'))

  const selectedVariantOutOfStock = computed(() => selectedVariant.value ? selectedVariant.value.stockLevel === 'OUT_OF_STOCK' : false)

  const availableOptions = computed(() => {
    const optionGroupMap = new Map<string, Map<ProductOptionGroupFragment['code'], ProductOptionFragment & { featuredAsset?: ProductVariantFragment['featuredAsset'] }>>()

    variants?.forEach((variant) => {
      variant.options.forEach((option) => {
        const optionGroupCode = option.group.code

        if (!optionGroupMap.has(optionGroupCode))
          optionGroupMap.set(optionGroupCode, new Map())

        const optionsMap = optionGroupMap.get(optionGroupCode)!

        if (!optionsMap.has(option.code)) {
          optionsMap.set(option.code, IMAGE_OPTION_GROUP_CODES.includes(optionGroupCode)
            ? { ...option, featuredAsset: variant.featuredAsset }
            : { ...option }) // deconstruct to make unreactive
        }
      })
    })

    const result: Record<ProductOptionGroupFragment['code'], Record<ProductOptionFragment['code'], ProductOptionFragment & { featuredAsset?: ProductVariantFragment['featuredAsset'] }>> = {}

    // Optional sort 'size' options or perform other custom logic as needed
    optionGroupMap.forEach((optionsMap, optionGroupCode) => {
      const optionsObject: Record<string, ProductOptionFragment & { featuredAsset?: ProductVariantFragment['featuredAsset'] }> = {}

      optionsMap.forEach((value, key) => optionsObject[key] = value)

      result[optionGroupCode] = optionGroupCode === 'size'
        ? sortGradeList(Object.keys(optionsObject)).reduce((sortedObj, code) => {
          sortedObj[code] = optionsObject[code]
          return sortedObj
        }, {} as Record<string, ProductOptionFragment & { featuredAsset?: ProductVariantFragment['featuredAsset'] }>)
        : optionsObject
    })

    return result
  })

  function initializeSelectedOptions() {
    const availableOptionGroupCodes = Object.keys(availableOptions.value)

    // get the codes from the query that are also in the available options
    const queryOptionGroupCodes = Object.keys(route.query).filter(key => availableOptionGroupCodes.includes(key))
    const fullySpecified = queryOptionGroupCodes.length === availableOptionGroupCodes.length

    if (queryOptionGroupCodes.length) {
      const validQueryOptions: Record<string, string> = {}

      // validate query options against available options
      queryOptionGroupCodes.forEach((optionGroupCode) => {
        const optionValue = route.query[optionGroupCode]

        if (typeof optionValue !== 'string')
          return

        const option = availableOptions.value[optionGroupCode][optionValue]
        if (option)
          validQueryOptions[optionGroupCode] = optionValue
        else
          console.warn(`Invalid option value ${optionValue} for option group ${optionGroupCode}`)
      })

      const validOptionGroupCodes = Object.keys(validQueryOptions)
      const allOptionsValid = validOptionGroupCodes.length === queryOptionGroupCodes.length

      if (allOptionsValid && fullySpecified) {
        // attempt to find a variant that matches the valid query options
        const matchingVariant = variants?.find(variant =>
          validOptionGroupCodes.every(optionGroupCode =>
            variant.options.some(option =>
              option.group.code === optionGroupCode && option.code === validQueryOptions[optionGroupCode],
            ),
          ),
        )

        if (matchingVariant) {
          if (matchingVariant.stockLevel === 'IN_STOCK') {
            matchingVariant.options.forEach(option => selectedOptions.value[option.group.code] = option.code)
            return
          }
          else {
            setBestPartialMatchVariantOptions(validQueryOptions)
            return
          }
        }
        return
      }
      else if (validOptionGroupCodes.length > 0) {
        // attempt to find a variant that matches the valid query options and is in stock
        setBestPartialMatchVariantOptions(validQueryOptions)
        return
      }
    }

    setDefaultVariantOptions()
  }

  function setDefaultVariantOptions() {
    const initialVariant = variants?.find(variant => variant.stockLevel === 'IN_STOCK')
    if (initialVariant) {
      initialVariant.options.forEach((option) => {
        selectedOptions.value[option.group.code] = option.code
      })
    }
    else {
      // console.warn('No in-stock variants available.')
    }
  }

  function setBestPartialMatchVariantOptions(queryOptions: Record<string, string>) {
    // attempt to find a variant that matches as many of the valid query options as possible and is in stock
    let bestMatch: ProductVariantFragment | null = null
    let maxMatchCount = 0

    variants?.forEach((variant) => {
      if (variant.stockLevel !== 'IN_STOCK')
        return

      const matchCount = Object.keys(queryOptions).reduce((count, code) =>
        count + (variant.options.some(option => option.group.code === code && option.code === queryOptions[code]) ? 1 : 0), 0)

      if (matchCount > maxMatchCount) {
        bestMatch = variant
        maxMatchCount = matchCount
      }
    })

    if (bestMatch) {
      (bestMatch as ProductVariantFragment).options.forEach((option) => {
        selectedOptions.value[option.group.code] = option.code
      })
    }
    else {
      console.warn('No partially matching in-stock variants found.')
    }
  }

  function isOptionAvailable(optionGroupCode: string, optionCode: string) {
    // If all variants are out of stock, no options should be available
    if (isOutOfStock.value)
      return false

    // If no options are selected, check if this option exists in any in-stock variant
    if (Object.keys(selectedOptions.value).length === 0) {
      return variants?.some(variant =>
        variant.stockLevel === 'IN_STOCK'
        && variant.options.some(option => option.group.code === optionGroupCode && option.code === optionCode),
      ) ?? false
    }

    if (!Object.keys(selectedOptions.value).length)
      return true

    const testSelectedOptions = { ...selectedOptions.value, [optionGroupCode]: optionCode }

    // If there are more than one option group, and only one option is selected, all options in that group should be available
    if (Object.keys(availableOptions.value).length > 1 && Object.keys(testSelectedOptions).length === 1)
      return true

    // If the current option is selected, it should always be available to allow deselection
    // if (selectedOptions.value[optionGroupCode] && selectedOptions.value[optionGroupCode] === optionCode)
    //   return true

    // Check if the combination of the current option with the selected options in other groups forms a valid variant
    return variants?.some((variant) => {
      const matchingVariant = variant.options.every(option => testSelectedOptions[option.group.code] === option.code)
      return matchingVariant && variant.stockLevel === 'IN_STOCK'
    })
  }

  function selectOption(optionGroupCode: string, optionCode: string | string[]) {
    if (selectedOptions.value[optionGroupCode] === optionCode)
      delete selectedOptions.value[optionGroupCode]
    else
      selectedOptions.value[optionGroupCode] = optionCode
  }

  function handleOptionChange(optionGroupCode: string, optionCode: string | string[]) {
    if (selectedOptions.value[optionGroupCode] === optionCode)
      return

    selectOption(optionGroupCode, optionCode)
  }

  function isColorGroupWithImages(optionGroupCode: string) {
    if (optionGroupCode !== 'color')
      return false

    return Object.values(availableOptions.value[optionGroupCode] ?? {}).every(option => option.featuredAsset?.preview)
  }

  const clearSelectedOptions = () => {
    selectedOptions.value = {}
  }

  initializeSelectedOptions()

  return {
    selectedOptions,
    availableOptions,
    selectedVariant,
    selectedVariants,
    isOutOfStock,
    selectedVariantOutOfStock,
    handleOptionChange,
    isOptionAvailable,
    isColorGroupWithImages,
    clearSelectedOptions,
    initializeSelectedOptions,
    selectOption,
  }
}
