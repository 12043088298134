<script setup lang="ts">
import { type ReviewsQuery, SortOrder } from '#graphql-operations'

const props = defineProps<{
  productId: string
}>()

const { t, d } = useI18n()

const currentPage = ref(1)
const TAKE = 4

const productReviewFormOpen = ref(false)
const reviewDetailOpen = ref(false)
const reviewsWithMedia = useState<{ reviewIndex: number, mediaIndices: number[] }[]>(() => [])
const currentReviewWithMediaIndex = ref(0)
const currentMediaIndex = ref(0)

const { data, status } = await useAsyncData(`reviews:${props.productId}`, async () => {
  const skip = currentPage.value > 1 ? (currentPage.value - 1) * TAKE : 0

  const result = await useGraphqlQuery('reviews', {
    productId: props.productId,
    options: {
      skip,
      take: TAKE,
      sort: {
        createdAt: SortOrder.DESC,
      },
    },
  }) as { data: ReviewsQuery }

  const reviews = result.data.product?.reviews

  if (reviews?.items?.length) {
    reviewsWithMedia.value = reviews?.items
      .map((review, index) => ({
        reviewIndex: index,
        mediaIndices: review.media ? Array.from({ length: review.media.length }, (_, i) => i) : [],
      }))
      .filter(review => review.mediaIndices.length > 0)
  }

  return result.data.product
}, {
  watch: [currentPage],
  lazy: true,
})

const reviews = computed(() => data.value?.reviews)
const reviewScore = computed(() => +(data.value?.reviewScore ?? 0).toFixed(1))
const reviewRatingDistribution = computed(() => data.value?.reviewRatingDistribution?.reduce((acc, review) => {
  acc[review.rating] = review.total
  return acc
}, {} as Record<number, number>))

function handleReviewOpen(reviewIndex: number, mediaIndex: number) {
  const reviewWithMediaIndex = reviewsWithMedia.value.findIndex(r => r.reviewIndex === reviewIndex)
  if (reviewWithMediaIndex !== -1) {
    currentReviewWithMediaIndex.value = reviewWithMediaIndex
    currentMediaIndex.value = reviewsWithMedia.value[reviewWithMediaIndex].mediaIndices.indexOf(mediaIndex)
    if (currentMediaIndex.value === -1) {
      currentMediaIndex.value = 0 // fallback to first media if the mediaIndex is not found
    }
  }
  reviewDetailOpen.value = true
}

const currentReview = computed(() => {
  const reviewWithMedia = reviewsWithMedia.value[currentReviewWithMediaIndex.value]
  const review = reviews.value?.items[reviewWithMedia.reviewIndex]
  return review
})
const isFirstReview = computed(() => currentReviewWithMediaIndex.value === 0 && currentMediaIndex.value === 0)
const isLastReview = computed(() => {
  const lastReviewWithMediaIndex = reviewsWithMedia.value.length - 1
  const lastReviewWithMedia = reviewsWithMedia.value[lastReviewWithMediaIndex]
  return currentReviewWithMediaIndex.value === lastReviewWithMediaIndex && currentMediaIndex.value === lastReviewWithMedia.mediaIndices.length - 1
})

function handleNextReview() {
  const currentReviewWithMedia = reviewsWithMedia.value[currentReviewWithMediaIndex.value]
  if (currentMediaIndex.value < currentReviewWithMedia.mediaIndices.length - 1) {
    currentMediaIndex.value++
  }
  else if (currentReviewWithMediaIndex.value < reviewsWithMedia.value.length - 1) {
    currentReviewWithMediaIndex.value++
    currentMediaIndex.value = 0
  }
}

function handlePreviousReview() {
  if (currentMediaIndex.value > 0) {
    currentMediaIndex.value--
  }
  else if (currentReviewWithMediaIndex.value > 0) {
    currentReviewWithMediaIndex.value--
    const previousReviewWithMedia = reviewsWithMedia.value[currentReviewWithMediaIndex.value]
    currentMediaIndex.value = previousReviewWithMedia.mediaIndices.length - 1
  }
}
</script>

<template>
  <div
    class="mb-[12vh] m-inline-auto max-w-[1920px] wfull p-inline-5 lg:p-inline-7.5 xl:p-inline-15"
  >
    <div class="block sm:flex">
      <div class="wfull lg:w-[35%] sm:w-1/2 sm:pr-[calc(5px+2.7vw)]">
        <div class="sticky top-0 sm:max-w-[400px]">
          <section class="mb10 pt4 md:block">
            <div>
              <a data-testid="customerReviewsLink" class="text-8 text-primary fw800 leading-tight tracking-[0.6px]">
                {{ t('general.customer_reviews.label') }}
              </a>
            </div>
          </section>
          <div class="bg-card text-card-foreground space-y-4">
            <template v-if="reviews?.totalItems">
              <div class="flex items-center gap-2">
                <span class="mr2 text-4xl font-bold">{{ reviewScore }}</span>
                <div class="flex items-center">
                  <Icon
                    v-for="n in 5"
                    :key="n"
                    :name="n <= Math.floor(reviewScore) ? 'ph:star-fill' : 'ph:star-duotone'"
                    class="h5 w5 text-primary"
                    :data-value="n"
                  />
                </div>
              </div>

              <p class="text-sm text-muted-foreground">
                {{ t('reviews.based_on_n_reviews.label', [reviews.totalItems]) }}
              </p>

              <div class="flex flex-col-reverse">
                <div v-for="rating in 5" :key="rating" class="mb-2 flex items-center">
                  <span class="w-8 flex items-center justify-center text-sm">
                    <Icon name="ph:star-fill" class="h5 w5 text-primary" />
                    {{ rating }}
                  </span>
                  <UiProgress
                    :model-value="(reviewRatingDistribution?.[rating] ?? 0) / reviews.totalItems * 100"
                    class="mx-2 h-2 flex-grow"
                  />
                  <span class="w-8 text-right text-sm">{{ reviewRatingDistribution?.[rating] ?? 0 }}</span>
                </div>
              </div>
            </template>

            <div v-else class="max-w-md flex flex-col items-center p4 md:p0">
              <div class="mb6.25 flex flex-col items-center md:flex-row">
                <div class="h-22.5 w-22.5 flex items-center p0 md:mr10">
                  <Icon name="ph:star-light" class="mb-4 h-22.5 w-22.5 text-slate-300" />
                </div>
                <div>
                  <p class="mb4 text-xl text-primary fw700 leading-[1.2]">
                    {{ t('general.no_reviews.label') }}
                  </p>
                  <p class="text-sm text-slate-400 fw600 leading-[1.43]">
                    {{ t('product.no_reviews.description') }}
                  </p>
                </div>
              </div>
            </div>

            <UiDialog v-model:open="productReviewFormOpen">
              <UiDialogTrigger as-child>
                <UiButton
                  class="h-auto w-full px-10 py-4 fw800 leading-none tracking-wide"
                  data-testid="writeReviewButton"
                >
                  {{ t('product.write_review') }}
                </UiButton>
              </UiDialogTrigger>

              <UiDialogScrollContent>
                <UiDialogHeader>
                  <UiDialogTitle>
                    {{ t('product.write_review') }}
                  </UiDialogTitle>
                  <UiDialogDescription>
                    {{ t('review.product_review_description') }}
                  </UiDialogDescription>
                </UiDialogHeader>
                <LazyProductReviewForm
                  v-if="productReviewFormOpen"
                  :product-id="productId"
                  @close="productReviewFormOpen = false"
                />
              </UiDialogScrollContent>
            </UiDialog>
          </div>
        </div>
      </div>

      <div v-if="reviews?.items?.length" class="wfull sm:block lg:w-[65%] sm:w-1/2 sm:pl-[calc(5px+2.7vw)]">
        <div class="sticky top-0 z1 pb-15">
          <template v-if="status === 'pending'">
            <ProductReviewSkeleton v-for="(_, index) in TAKE" :key="index" class="py4" />
          </template>
          <ProductReview
            v-for="(review, reviewIndex) in reviews.items"
            v-else
            :key="review.id"
            :review="review"
            class="py4"
            @open:media="handleReviewOpen(reviewIndex, $event)"
          />

          <div v-if="reviews.totalItems > TAKE" class="mt-6 flex items-center">
            <LazyUiPagination
              v-slot="{ page }"
              v-model:page="currentPage"
              :total="reviews.totalItems"
              :items-per-page="TAKE"
              show-edges
              :disabled="status === 'pending'"
            >
              <LazyUiPaginationList v-slot="{ items }" class="flex items-center gap-1">
                <LazyUiPaginationPrev />

                <template v-for="(item, index) in items">
                  <LazyUiPaginationListItem v-if="item.type === 'page'" :key="index" :value="item.value" as-child>
                    <LazyUiButton class="h-10 w-10 p-0" :variant="item.value === page ? 'default' : 'outline'">
                      {{ item.value }}
                    </LazyUiButton>
                  </LazyUiPaginationListItem>
                  <LazyUiPaginationEllipsis v-else :key="item.type" :index="index" />
                </template>

                <LazyUiPaginationNext />
              </LazyUiPaginationList>
            </LazyUiPagination>
          </div>
        </div>
      </div>
    </div>

    <UiDialog v-model:open="reviewDetailOpen">
      <UiDialogTrigger as-child>
        <button class="sr-only" />
      </UiDialogTrigger>
      <UiDialogContent class="max-w-5xl border-0 bg-transparent p-0">
        <UiDialogHeader class="sr-only">
          <UiDialogTitle>
            {{ currentReview?.authorName }}
          </UiDialogTitle>
          <UiDialogDescription>
            <time :datetime="currentReview?.createdAt">{{ d(currentReview?.createdAt) }}</time>
          </UiDialogDescription>
        </UiDialogHeader>

        <div class="group absolute right-0 top-0 z1 h15 w15 flex cursor-pointer items-center justify-center pb0 md:hidden">
          <button
            class="relative hfull wfull flex cursor-pointer items-center justify-center of-hidden border-0 rd-br-0.5 rd-tr-0.5 bg-transparent outline-none transition-all duration-300 ease-in-out will-change-transform,opacity"
            @click="reviewDetailOpen = false"
          >
            <span class="relative top-0 h9 w9 flex shrink-0 grow-0 basis-9 cursor-pointer items-center justify-center of-hidden border-0 rd-1/2 bg-muted p0 outline-none will-change-transform,opacity group-hover:bg-muted/50" style="transition: background 300ms ease-in-out, transform 75ms ease-in-out">
              <Icon name="ph:x-bold" />
            </span>
          </button>
        </div>

        <LazyProductReviewDetail
          v-if="currentReview"
          :review="currentReview"
          :media-index="currentMediaIndex"
          :is-first="isFirstReview"
          :is-last="isLastReview"
          @next="handleNextReview"
          @previous="handlePreviousReview"
        />
      </UiDialogContent>
    </UiDialog>
  </div>
</template>
