import { AssetType } from '#graphql-operations'
import type { AssetFragment } from '#graphql-operations'

export function isVideo(asset: AssetFragment | string) {
  if (typeof asset === 'string') {
    return asset.includes('video')
  }
  return asset.type === AssetType.VIDEO
}
export function isImage(asset: AssetFragment | string) {
  if (typeof asset === 'string') {
    return asset.includes('image')
  }
  return asset.type === AssetType.IMAGE
}

export const isBinary = (asset: AssetFragment) => asset.type === AssetType.BINARY
