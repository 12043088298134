function isValidGrading(grading: string) {
  return /[sml\dx]/i.test(grading)
}

export function sortGradeList(values: string[]) {
  if (!values.every(isValidGrading))
    return values

  const calculateGradePriority = (grade: string) => {
    let priorityFactor = Number.NaN
    const lowerGrade = grade.toLowerCase()

    if (lowerGrade.includes('s'))
      priorityFactor = -1
    else if (lowerGrade.includes('m'))
      priorityFactor = 0
    else if (lowerGrade.includes('l'))
      priorityFactor = 1

    const numericalValue = Number(lowerGrade.match(/\d+(?!X)/))
    const countOfX = lowerGrade.match(/x*/)?.[0].length || 0
    const multiplier = numericalValue || countOfX + 1

    return priorityFactor * multiplier
  }

  return values.sort((firstGrade: any, secondGrade: any): any => {
    const numFirstGrade = Number(firstGrade)
    const numSecondGrade = Number(secondGrade)

    if (!Number.isNaN(numFirstGrade) && !Number.isNaN(numSecondGrade))
      return numFirstGrade - numSecondGrade

    if (!Number.isNaN(numFirstGrade) && Number.isNaN(numSecondGrade))
      return -1

    if (Number.isNaN(numFirstGrade) && !Number.isNaN(numSecondGrade))
      return 1

    return calculateGradePriority(firstGrade) - calculateGradePriority(secondGrade)
  })
}
