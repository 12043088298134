<script setup lang="ts">
import type { AssetFragment } from '#graphql-operations'
import { useCarouselScroll } from '~/composables/useCarouselScroll'
import { isVideo } from '~/utils/assets'

const props = defineProps<{
  assets: (AssetFragment & { alt: string })[]
  initialIndex: number
}>()

const carouselRef = ref<HTMLElement | null>(null)
const itemWidth = ref(0)

const { x } = useScroll(carouselRef, { behavior: 'smooth' })

const { width: carouselWidth } = useElementSize(carouselRef)

useCarouselScroll(carouselRef as Ref<HTMLElement>)

useResizeObserver(carouselRef, (entries) => {
  const [entry] = entries

  itemWidth.value = entry?.target?.firstElementChild?.clientWidth || 0
})

const currentPage = computed(() => {
  if (!itemWidth.value) {
    return 0
  }

  return Math.round(x.value / itemWidth.value) + 1
})

const pages = computed(() => {
  if (!itemWidth.value) {
    return 0
  }

  return props.assets.length - Math.round(carouselWidth.value / itemWidth.value) + 1
})

const isFirst = computed(() => currentPage.value <= 1)
const isLast = computed(() => currentPage.value === pages.value)

function onClickNext() {
  x.value += itemWidth.value
}

function onClickPrev() {
  x.value -= itemWidth.value
}

function onClick(page: number) {
  x.value = (page - 1) * itemWidth.value
}

const isFullscreen = ref(false)
const isLocked = useScrollLock(document?.body)

function openFullscreen() {
  isFullscreen.value = true
  isLocked.value = true
}

function closeFullscreen() {
  isFullscreen.value = false
  isLocked.value = false
}
</script>

<template>
  <div
    data-testid="productSliderFullscreen"
    class="z101 flex flex-col bg-background"
    :class="{
      'inset-0 fixed bg-background': isFullscreen,
    }"
  >
    <div data-testid="productSlider" class="hfull flex flex-col">
      <div
        ref="carouselRef"
        class="h-full flex snap-x snap-mandatory overflow-x-scroll no-scrollbar [&>*]:relative [&>*]:min-w-full [&>*]:snap-center [&>*]:snap-always"
      >
        <div
          v-for="(asset, index) in assets" :key="index"
          :class="isFullscreen ? '[&>div]:h-full' : '[&>div]:h-auto'"
          @click="openFullscreen"
        >
          <Wrapper v-bind=" isFullscreen ? { class: 'relative of-hidden bg-slate-100', as: 'div' } : undefined">
            <div
              data-testid="productAsset"
              class="relative w-full of-hidden rd-1 bg-slate-100 pt-[calc(133.333%)]"
              :class="isFullscreen ? 'h-full pt0 w-full' : ''"
            >
              <video
                v-if="isVideo(asset)"
                height="100%"
                width="100%"
                :preload="index < 2 ? 'auto' : 'metadata'"
                :importance="index < 2 ? 'high' : 'low'"
                :fetchpriority="index < 2 ? 'high' : 'low'"
                :loading="index < 2 ? 'eager' : 'lazy'"
                :poster="asset.customFields?.videoPreview?.preview || asset.preview"
                data-testid="productVideoView"
                class="absolute inset-0 m-auto aspect-3/4 hfull max-h-full max-w-full wfull rd-0.5 object-cover object-center-center align-bottom"
                autoplay
                muted
                playsinline
                loop
              >
                <source :src="asset.source" type="video/mp4">
              </video>
              <img
                v-else
                height="100%"
                width="100%"
                :decoding="index < 2 ? 'sync' : 'async'"
                :importance="index < 2 ? 'high' : 'low'"
                :fetchpriority="index < 2 ? 'high' : 'low'"
                :loading="index < 2 ? 'eager' : 'lazy'"
                sizes="100vw"
                :src="asset.preview"
                :alt="asset.alt"
                data-testid="productImageView"
                class="absolute inset-0 m-auto aspect-3/4 hfull max-h-full max-w-full wfull rd-0.5 object-cover object-center-center align-bottom"
              >
            </div>
          </Wrapper>
        </div>
      </div>

      <div
        data-testid="productSliderSliderNav"
        :class="isFullscreen ? 'relative flex flex-row wfull z1 bg-background' : 'bottom-4.5 relative flex flex-row wfull z1'"
      >
        <button v-if="isFullscreen" class="of-hidden rd-0 border-none bg-transparent p5" @click="onClickPrev">
          <Icon name="ph:caret-left-bold" class="block h6 w6 shrink-0 grow-0 basis-6" />
        </button>

        <div
          data-testid="productSliderBullets"
          :class="isFullscreen
            ? 'items-center flex flex-row justify-center m-auto backdrop-blur-[6px] rd-1.25 px1 py0.5 w-fit bg-slate-100/10'
            : 'items-center flex flex-row justify-center m-auto inset-x-0 backdrop-blur-[6px] rd-1.25 px1 py0.5 bg-slate-100/10 relative'"
        >
          <div
            v-for="(_, index) in assets" :key="index"
            :data-testid="`productSliderBullets_bullet_${index}`"
            class="m0.5 h1.25 w1.25 rd-1/2"
            :class="currentPage === (index + 1) ? 'bg-primary' : 'bg-slate-300'"
          />
        </div>

        <button v-if="isFullscreen" class="of-hidden rd-0 border-none bg-transparent p5" @click="onClickNext">
          <Icon name="ph:caret-right-bold" class="block h6 w6 shrink-0 grow-0 basis-6" />
        </button>
      </div>
    </div>

    <div
      v-if="isFullscreen"
      class="absolute right-5 top-5 z1 h9.75 w9.75 flex items-center justify-center of-hidden rd-1/2 bg-background shadow"
      data-testid="productSliderFullscreen-closeButton"
    >
      <button
        v-wave
        class="relative inline-block h9 w9 shrink-0 grow-0 basis-9 cursor-pointer of-visible border-0 rd-1/2 bg-transparent p1.25 outline-none will-change-transform,opacity"
        @click="closeFullscreen"
      >
        <Icon name="ph:x-bold" class="block h6 w6 shrink-0 grow-0 basis-6 text-slate-400" />
      </button>
    </div>
  </div>
</template>
