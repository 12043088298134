<script setup lang="ts">
import type { SearchQuery, SearchResultFragment } from '#graphql-operations'

const props = defineProps<{
  productId: string
}>()

const { t } = useI18n()

const TAKE = 20

const { data, status } = await useAsyncData('search-similar-products', async () => {
  const result = await useGraphqlQuery('search', {
    search: {
      similarProductID: props.productId,
      take: TAKE,
      skip: 0,
    },
  })

  return result.data.search as SearchQuery['search']
}, {
  server: false,
  lazy: true,
  default: () => ({
    items: [] as SearchResultFragment[],
    totalItems: 0,
  }),
})
</script>

<template>
  <ProductListLane
    :loading="!!data?.items?.length && status === 'pending'"
    :items="data?.items"
    :headline="t('product.similar_products')"
    :subline="t('product.you_might_also_like')"
  />
</template>
