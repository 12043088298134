<script setup lang="ts">
import type { SearchResultFragment } from '#graphql-operations'
import { useCarouselScroll } from '~/composables/useCarouselScroll'

const props = defineProps<{
  headline: string
  subline: string
  items: SearchResultFragment[]
  loading: boolean
}>()

const carouselRef = ref<HTMLElement | null>(null)
const itemWidth = ref(0)

const { x } = useScroll(carouselRef, { behavior: 'smooth' })

const { width: carouselWidth } = useElementSize(carouselRef)

useCarouselScroll(carouselRef as Ref<HTMLElement>)

useResizeObserver(carouselRef, (entries) => {
  const [entry] = entries

  itemWidth.value = entry?.target?.firstElementChild?.clientWidth || 0
})

const visibleItems = computed(() => {
  if (!itemWidth.value || !carouselWidth.value) {
    return 1
  }
  return Math.floor(carouselWidth.value / itemWidth.value)
})

const currentPage = computed(() => {
  if (!itemWidth.value) {
    return 0
  }

  return Math.round(x.value / (itemWidth.value * visibleItems.value)) + 1
})

const pages = computed(() => {
  if (!itemWidth.value || !props.items?.length) {
    return 0
  }

  return Math.ceil(props.items.length / visibleItems.value)
})

function onClickNext() {
  x.value += itemWidth.value * visibleItems.value
}

function onClickPrev() {
  x.value -= itemWidth.value * visibleItems.value
}

const isFirst = computed(() => currentPage.value <= 1)
const isLast = computed(() => currentPage.value === pages.value)
</script>

<template>
  <section data-testid="productListRecommendationLane">
    <header data-testid="header" class="relative mx4 my6 flex items-center justify-between md:mx10 xl:mx15">
      <div class="wfull flex ws-pre">
        <div class="mb6 flex flex-col md:m0 md:gap2 md:text-left">
          <span
            data-testid="recommendationLaneSubline"
            class="inline-block text-center text-2.5 text-slate-400 fw700 leading-7 uppercase md:text-left md:text-xs md:leading-4.5"
          >
            {{ subline }}
          </span>
          <span
            data-testid="recommendationLaneHeadline"
            class="inline-block text-center text-base text-primary leading-tight md:text-left md:text-8 md:fw800"
          >
            {{ headline }}
          </span>
        </div>
      </div>

      <div>
        <div
          data-testid="horizontalSliderControl"
          class="items-center text-base text-primary fw600 hidden md:flex"
        >
          <span
            data-testid="slideControlArrowStart"
            :data-disabled="isFirst"
            class="mt0.5 w1.75 flex origin-cc rotate-180 cursor-pointer items-center justify-center bg-center-center px4 py2.5 text-slate-300 transition-transform duration-75 ease-in-out active:scale-80"
            @click="onClickPrev"
          >
            <Icon name="ph:caret-right-bold" class="block size-4 shrink-0 grow-0 basis-4 text-primary" :class="{ 'text-slate-300': isFirst }" />
          </span>

          <span
            data-testid="slideControlText"
            class="inline-block min-w-8.5 ws-nowrap text-center text-primary"
          >
            {{ currentPage }} / {{ pages }}
          </span>

          <span
            data-testid="slideControlArrowEnd"
            :data-disabled="isLast"
            class="mt0.5 w1.75 flex origin-cc cursor-pointer items-center justify-center bg-center-center px4 py2.5 text-slate-300 transition-transform duration-75 ease-in-out -translate-x-px active:scale-80"
            @click="onClickNext"
          >
            <Icon name="ph:caret-right-bold" class="block h4 w4 shrink-0 grow-0 basis-4 text-primary" :class="{ 'text-slate-300': isLast }" />
          </span>
        </div>
      </div>
    </header>

    <div
      data-testid="sliderContainer"
      class="relative hfull wfull"
    >
      <div
        ref="carouselRef"
        data-testid="sliderWrapper"
        class="hfull w-[calc(100%+0px)] flex flex-nowrap touch-manipulation snap-x snap-mandatory scroll-px4 scroll-py0 of-x-auto of-y-hidden px4 py0 no-scrollbar [&>*]:mr1.25 [&>*]:w0 [&>*]:shrink-0 [&>*]:basis-[calc(45.4545%-5px)] [&>*]:snap-start md:scroll-px10 md:scroll-py0 md:px10 md:py0 xl:scroll-px15 xl:scroll-py0 xl:px15 xl:py0 lg:[&>*]:mr1.25 lg:[&>*]:w0 lg:[&>*]:shrink-0 lg:[&>*]:basis-[calc(25%-5px)] lg:[&>*]:snap-start md:[&>*]:mr1.25 md:[&>*]:w0 md:[&>*]:shrink-0 md:[&>*]:basis-[calc(40%-5px)] md:[&>*]:snap-start xl:[&>*]:mr1.25 xl:[&>*]:w0 xl:[&>*]:shrink-0 xl:[&>*]:basis-[calc(20%-5px)] xl:[&>*]:snap-start"
      >
        <template v-if="loading">
          <ProductTileSkeleton v-for="n in 6" :key="n" />
        </template>
        <template v-else-if="items">
          <ProductTileV2
            v-for="item in items"
            :key="item.id"
            :item="item"
            :css-containment-will-be-enabled="false"
            :css-containment-enabled="false"
          >
            <template #header>
              <WishlistToggle v-slot="{ alert, isItemWishlisted, isAnimating, handleWishlistClick }" :product-id="item.id" :product-variant-id="item.productVariantId">
                <button
                  v-wave="{ color: 'hsl(var(--background)' }"
                  type="button"
                  data-testid="addToWishlist"
                  class="group/wishlist relative m0 h6 w6 flex shrink-0 grow-0 basis-9 cursor-pointer items-center justify-center of-visible border-0 rd-1/2 bg-none p2 text-primary outline-none transition-opacity duration-300 ease-in will-change-transform,opacity"
                  :aria-label="isItemWishlisted ? 'Položka na seznamu přání' : 'Přidat položku na seznam přání'"
                  :aria-pressed="isItemWishlisted"
                  @click.stop.prevent="handleWishlistClick"
                >
                  <Icon
                    :name="isItemWishlisted ? 'ic:heart-fill' : 'ic:heart'"
                    class="block h6 w6 shrink-0 grow-0 basis-6"
                    :class="[
                      isItemWishlisted ? 'text-primary' : 'text-slate-400 ',
                      {
                        'group-hover/wishlist:animate-[2s_ease-in-out_0s_infinite_normal_none_running_heartbeat]': !isItemWishlisted && !isAnimating,
                        'animate-[500ms_ease-in-out_0s_1_normal_none_running_pulseOutward]': isAnimating && isItemWishlisted,
                        'animate-[500ms_ease-in-out_0s_1_normal_none_running_pulseInward]': isAnimating && !isItemWishlisted,
                      },
                    ]"
                    mode="svg"
                    :data-testid="`WishListIcon-${isItemWishlisted ? 'filled' : 'empty'}`"
                  />
                  <Icon
                    name="ic:heart-fill"
                    class="absolute block h6 w6 shrink-0 grow-0 basis-6 fill-white stroke-4 stroke-white text-white -z1"
                    mode="svg"
                  />
                  <div class="absolute inset-0" />
                </button>
                <div class="absolute left-0 top-0 z1 wfull translate-z-0">
                  <Transition
                    enter-active-class="duration-550 ease-[cubic-bezier(0.75,0.23,0.57,0.65)] will-change-[transform,opacity]"
                    enter-from-class="transform translate-y-[-100%] opacity-0"
                    enter-to-class="transform translate-y-0 opacity-100"
                    leave-active-class="duration-550 ease-[cubic-bezier(0.75,0.23,0.57,0.65)] will-change-[transform,opacity]"
                    leave-from-class="transform translate-y-0 opacity-100"
                    leave-to-class="transform translate-y-[-100%] opacity-0"
                  >
                    <div
                      v-if="alert"
                      class="fixed left-0 top-0 z300 wfull flex animate-in bg-destructive p3.25 text-center text-sm text-black leading-6 md:py0"
                      type="FAILURE"
                      direction="down"
                    >
                      <span data-testid="toastContent" class="wfull flex grow-1 flex-col md:p2">
                        <span>{{ alert }}</span>
                      </span>
                    </div>
                  </Transition>
                </div>
              </WishlistToggle>
            </template>
          </ProductTileV2>
        </template>
      </div>

      <div v-if="isFirst" class="pointer-events-none absolute left-0 right-0 top-0 w4 flex cursor-default items-center justify-center pb0 md:w10 xl:w15" disabled="" />
      <div v-else class="absolute bottom-0 left-0 top-0 w4 flex cursor-pointer items-center justify-center pb0 md:w10 xl:w15">
        <button
          v-wave="{ color: 'hsl(var(--background)' }"
          data-testid="arrowLeft"
          class="relative hfull wfull flex items-center justify-center of-hidden rd-br-1 rd-tr-1 bg-transparent transition-colors transition-opacity duration-300 ease-in-out"
          @click="onClickPrev"
        >
          <span
            v-wave="{ color: 'hsl(var(--background)' }"
            class="transition-background,transform relative h9 w9 flex shrink-0 grow-0 basis-9 items-center justify-center of-hidden rd-1/2 bg-card p0 shadow-lg duration-[300ms,75ms] ease-in-out -top-12.5"
          >
            <Icon name="ph:caret-left-bold" class="block h4 w4 shrink-0 grow-0 basis-4" />
          </span>
        </button>
      </div>

      <div v-if="isLast" class="pointer-events-none absolute bottom-0 right-0 top-0 w4 flex cursor-default items-center justify-center pb0 md:w10 xl:w15" />
      <div v-else class="absolute bottom-0 right-0 top-0 w4 flex cursor-pointer items-center justify-center pb0 md:w10 xl:w15">
        <button
          v-wave="{ color: 'hsl(var(--background)' }"
          data-testid="arrowRight"
          class="relative hfull wfull flex items-center justify-center of-hidden rd-br-1 rd-tr-1 bg-transparent transition-colors transition-opacity duration-300 ease-in-out"
          @click="onClickNext"
        >
          <span
            v-wave="{ color: 'hsl(var(--background)' }"
            class="relative h9 w9 flex shrink-0 grow-0 basis-9 cursor-pointer items-center justify-center of-hidden of-hidden border-0 rd-1/2 bg-card p0 shadow-lg outline-none will-change-transform,opacity -top-12.5"
            style="transition: background 300ms ease-in-out, transform 75ms ease-in-out;"
          >
            <Icon name="ph:caret-right-bold" class="block h4 w4 shrink-0 grow-0 basis-4" />
          </span>
        </button>
      </div>
    </div>
  </section>
</template>
