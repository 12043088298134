<script setup lang="ts">
import type { HTMLAttributes } from 'vue'
import { cn } from '~/utils/classes'

interface SkeletonProps {
  class?: HTMLAttributes['class']
}

const props = defineProps<SkeletonProps>()
</script>

<template>
  <span
    class="text-2.5"
    :class="cn('ml-2.5 h-4.5 flex items-center gap-1 rounded bg-background px-1.5 pt-px text-white fw700  tracking-wide', props.class)"
  >
    <slot />
  </span>
</template>
