import type { MaybeRef } from 'vue'
import type { ProductFragment } from '#graphql-operations'

export async function useProduct(id: MaybeRef<string>) {
  const { facetValues } = useFacetsState()

  const { data: product, pending, status } = await useAsyncData(`product:${unref(id)}`, async () => {
    const result = await useGraphqlQuery('product', {
      id: unref(id),
    })

    const product = result.data.product

    if (product?.facetValues) {
      facetValues.value = product.facetValues.reduce((acc, facetValue) => ({ ...acc, [facetValue.id]: facetValue }), {
        ...facetValues.value,
      })
    }
    return product as ProductFragment
  })

  return {
    product,
    pending,
    status,
  }
}
