<script setup lang="ts">
import type { AssetFragment } from '#graphql-operations'

defineProps<{
  assets: AssetFragment[]
}>()

const emit = defineEmits<{
  (event: 'open:media', index: number): void
}>()
</script>

<template>
  <NCarousel
    :items="assets"
    :ui="{
      item: 'snap-end pr-2 w-[calc(25%-8px)]  xl:w-[calc(16.66%-8px)] basis-full basis-1/2',
    }"
    class="w-full"
    arrows
  >
    <template #default="{ item, index }">
      <button
        class="relative hfull wfull border-none bg-slate-100 p0"
        @click="emit('open:media', index)"
      >
        <div class="relative hfull wfull of-hidden rd-1 bg-slate-100 pt-[calc(133.333%)]">
          <NuxtImg
            v-if="isImage(item)"
            height="100%"
            width="100%"
            decoding="async"
            loading="lazy"
            sizes="33.333vw lg:50vw"
            :src="item?.preview"
            :alt="`Review image ${index + 1}`"
            data-testid="productReviewImageView"
            class="absolute inset-0 m-auto aspect-3/4 hfull max-h-full max-w-full wfull select-none rd-0.5 object-cover object-center-center align-bottom"
          />
          <template v-else-if="isVideo(item)">
            <div class="absolute inset-0 z1 flex items-center justify-center">
              <div class="h10 w10 flex items-center justify-center border border-white/50 rd-full bg-white/40 shadow-xl backdrop-blur transition-all duration-150 hover:bg-white/50">
                <Icon name="ph:play-fill" class="block h-5 w-5 shrink-0 grow-0 basis-5 text-white drop-shadow-md" />
              </div>
            </div>
            <video
              height="100%"
              width="100%"
              preload="auto"
              loading="lazy"
              data-testid="productReviewVideoView"
              class="absolute inset-0 m-auto aspect-3/4 hfull max-h-full max-w-full wfull rd-0.5 object-cover object-center-center align-bottom"
            >
              <source :src="item.preview" type="video/mp4">
            </video>
          </template>
        </div>
      </button>
    </template>
  </NCarousel>
</template>
