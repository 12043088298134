<script setup lang="ts">
import { NuxtLinkLocale } from '#components'

const uspList = {
  standard: [
    {
      icon: 'ph:package-bold',
      text: 'Doprava zdarma',
      description: '',
      badge: {
        text: 'Zdarma',
        color: 'bg-green-600',
      },
      targetPath: '',
    },
    {
      icon: 'ph:arrow-counter-clockwise-bold',
      text: 'Vrácení zboží zdarma',
      badge: {
        text: 'Zdarma',
        color: 'bg-green-600',
      },
      description: '',
      targetPath: '',
    },
    {
      icon: 'ph:shield-check-bold',
      text: 'Záruka 24 měsíců',
      description: '',
      targetPath: '',
    },
    {
      icon: 'ph:credit-card-bold',
      text: 'Bezpečná platba',
      description: '',
      targetPath: '',
    },
  ],
}
</script>

<template>
  <div
    data-testid="uspList"
    class="my2 flex flex-col border border-slate-200 rd"
  >
    <template v-for="(usp, index) in uspList.standard" :key="index">
      <Wrapper
        v-bind="{
          'as': usp.targetPath ? (NuxtLinkLocale as unknown as 'a') : 'div',
          'data-testid': usp.targetPath ? 'link' : 'usp',
        }"
        class="box-border flex flex-col items-start gap2.5 px4 py3 not-last:border-b not-last:border-slate-200 plain-link"
      >
        <div class="wfull flex flex-row items-center">
          <div
            data-testid="UspContainer"
            class="box-border min-w-0 flex flex-1 items-center py1"
          >
            <Icon :name="usp.icon" class="block size-4 shrink-0 grow-0 basis-4" />

            <span data-testid="UspText" class="ml4.5 text-xs fw600 tracking-[0.4px] hyphens-auto">
              {{ usp.text }}
            </span>
          </div>

          <UspListBadge v-if="usp.badge" data-testid="uspMovFreeBadge" :class="usp.badge.color">
            {{ usp.badge.text }}
          </UspListBadge>
        </div>

        <div v-if="usp.description" class="relative wfull rd bg-slate-100 py2 pl3 pr pr6 text-2.5 leading-[1.5]">
          {{ usp.description }}
          <Icon name="ph:info" class="absolute right-2 top-2 block h3 w3 shrink-0 grow-0 basis-3 cursor-pointer" />
        </div>
      </Wrapper>
    </template>
  </div>
</template>
