<script setup lang="ts">
import type { ProductReviewFragment } from '#graphql-operations'

defineProps<{
  review: ProductReviewFragment
}>()

const emit = defineEmits<{
  (event: 'open:media', mediaIndex: number): void
}>()

const { locale } = useI18n()
const dayjs = useDayjs()
</script>

<template>
  <div class="bg-card text-card-foreground">
    <div class="mb-4 flex items-center">
      <UiAvatar class="mr-4 h-10 w-10">
        <UiAvatarFallback>
          {{ review.authorName.split(' ').map(n => n[0]).join('') }}
        </UiAvatarFallback>
      </UiAvatar>
      <div>
        <h4 class="font-semibold">
          {{ review.authorName }}
        </h4>
        <div class="flex items-center">
          <Icon
            v-for="(_, i) in Array.from({ length: 5 }, (_, i) => i)"
            :key="i"
            class="h4 w4 text-primary"
            :name="i < review.rating ? 'ph:star-fill' : 'ph:star-duotone'"
          />
          <span class="ml-2 text-sm text-muted-foreground">{{ dayjs(review.createdAt).locale(locale).fromNow() }}</span>
        </div>
      </div>
    </div>

    <p class="mb-4 text-sm text-gray-600">
      {{ review.body }}
    </p>

    <div v-if="review.media?.length" class="flex flex-wrap gap-2">
      <NCarousel
        :items="review.media"
        :ui="{
          item: 'snap-end pr-2 w-[calc(25%-8px)]  xl:w-[calc(16.66%-8px)]',
        }"
        class="w-full"
        arrows
      >
        <template #default="{ item, index }">
          <button
            class="relative hfull wfull border-none bg-slate-100 p0"
            @click="emit('open:media', index)"
          >
            <div class="relative hfull wfull of-hidden rd-1 bg-slate-100 pt-[calc(133.333%)] [&>img,&>video]:absolute [&>img,&>video]:inset-0 [&>img,&>video]:m-auto [&>img,&>video]:hfull [&>img,&>video]:max-h-full [&>img,&>video]:max-w-full [&>img,&>video]:wfull [&>img,&>video]:object-cover [&>img,&>video]:object-center-center">
              <NuxtImg
                v-if="item?.mimeType?.startsWith('image')"
                height="100%"
                width="100%"
                decoding="async"
                loading="lazy"
                sizes="20vw"
                :src="item?.fileUrl"
                :alt="`Review image ${index + 1}`"
                data-testid="productReviewImageView"
                class="aspect-3/4 select-none rd-0.5 align-bottom"
              />
              <template v-else-if="item?.mimeType?.startsWith('video')">
                <div class="absolute inset-0 z1 flex items-center justify-center">
                  <div class="h10 w10 flex items-center justify-center border border-white/50 rd-full bg-white/40 shadow-xl backdrop-blur transition-all duration-150 hover:bg-white/50">
                    <Icon name="ph:play-fill" class="block h-5 w-5 shrink-0 grow-0 basis-5 text-white drop-shadow-md" />
                  </div>
                </div>
                <video
                  height="100%"
                  width="100%"
                  preload="auto"
                  loading="lazy"
                  :alt="`Review video ${index + 1}`"
                  data-testid="productReviewVideoView"
                  class="aspect-3/4 rd-0.5 align-bottom"
                >
                  <source :src="item?.fileUrl" :type="item?.mimeType">
                </video>
              </template>
            </div>
          </button>
        </template>

        <template #next="{ onClick, disabled }">
          <UiButton
            v-if="!disabled"
            variant="outline"
            size="icon"
            class="absolute top-1/2 transform rounded-full shadow -right-5 -translate-y-1/2 rtl:[&_span:last-child]:rotate-180"
            aria-label="Next"
            @click="onClick"
          >
            <Icon name="ph:caret-right-bold" />
          </UiButton>
          <div v-else disabled class="pointer-events-none absolute bottom-0 right-0 top-0 w10 flex cursor-default items-center justify-center pb0" />
        </template>

        <template #prev="{ onClick, disabled }">
          <UiButton
            v-if="!disabled"
            variant="outline"
            size="icon"
            class="absolute top-1/2 transform rounded-full shadow -left-5 -translate-y-1/2 rtl:[&_span:first-child]:rotate-180"
            aria-label="Prev"
            @click="onClick"
          >
            <Icon name="ph:caret-left-bold" />
          </UiButton>
          <div v-else disabled class="pointer-events-none absolute bottom-0 left-0 top-0 w10 flex cursor-default items-center justify-center pb0" />
        </template>
      </NCarousel>
    </div>
  </div>
</template>
