<script setup lang="ts">
const props = defineProps<{
  productId: string
}>()

const { t } = useI18n()

const history = useProductHistory()
const historyItems = computed(() => history?.history?.value.filter(item => item.id !== props.productId))
</script>

<template>
  <ProductListLane
    :items="historyItems"
    :loading="false"
    :subline="t('product.pick_up_where_you_left_off')"
    :headline="t('product.last_viewed_products')"
  />
</template>
