<script
  setup
  lang="ts"
>

</script>

<template>
  <div class="bg-card text-card-foreground">
    <div class="mb-4 flex items-center">
      <UiSkeleton class="mr-4 h-10 w-10 shrink-0 rounded-full" />
      <div>
        <UiSkeleton class="my-1 h-4 w-[124px]" />
        <div class="flex items-center">
          <Icon
            v-for="(_, i) in Array.from({ length: 5 }, (_, i) => i)"
            :key="i"
            class="h4 w4 animate-pulse text-muted"
            name="ph:star-fill"
          />
        </div>
      </div>
    </div>

    <div>
      <UiSkeleton class="my2.5 h-3.5" />
      <UiSkeleton class="my2.5 h-3.5 w-3/4" />
    </div>
  </div>
</template>
